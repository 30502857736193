import { Radio } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ECButton } from '../../../components/ECButton';
import { Loader } from '../../../components/Loader';

import { getPlans } from '../../../store/reducers/plansSlice';
import { payPlan } from '../../../utils/payPlan';
import { Box, Form } from '../styles';
import { FormControlLabel, Label, LoaderWrapper, RadioGroup } from './styles';

const CURRENT = '(current)';

const getEndDate = (date) => {
  date = new Date(date * 1000);

  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export const Plans = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userDetailsSlice);
  const { plans } = useSelector((state) => state.plansSlice);
  const { subs } = userInfo;
  const [planId, setPlanType] = useState(null);
  const currentPlan = subs?.items?.slice(-1)[0]?.plan?.id || 'free';
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setPlanType(currentPlan);
  }, [subs]);

  useEffect(() => {
    if (!plans) {
      dispatch(getPlans());
    }
  }, []);

  const handleChange = (e) => {
    setPlanType(e.target.value);
  };

  const handleCancel = () => {
    setPlanType(currentPlan);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    payPlan(planId, setIsLoading);
  };

  const isDisabled = currentPlan === planId;

  return plans ? (
    <Form onSubmit={onSubmit} className="form-wrapper">
      <RadioGroup defaultValue={'free'} value={planId} onChange={handleChange}>
        <div>
          <Label>Free plan {currentPlan === 'free' && CURRENT}</Label>
          <FormControlLabel
            value="free"
            control={<Radio disabled={currentPlan !== 'free'} />}
            label="$0.00"
            labelPlacement="start"
          />
        </div>

        {plans.map((plan) => (
          <div key={plan.id}>
            <Label>
              {plan.interval === 'month' ? 'Monthly' : 'Annual'} subscription{' '}
              {currentPlan === plan.id && `(until ${getEndDate(subs.current_period_end)})`}
            </Label>
            <FormControlLabel
              value={plan.id}
              control={<Radio />}
              label={`$${plan.unit_amount / 100}.00 / ${plan.interval}`}
              labelPlacement="start"
            />
          </div>
        ))}
      </RadioGroup>
      <Box $gapSize={12}>
        <ECButton variant="Primary" type="submit" disabled={isDisabled} loading={isLoading}>
          Save changes
        </ECButton>
        <ECButton variant="Outline" disabled={isDisabled} onClick={handleCancel}>
          Cancel
        </ECButton>
      </Box>
    </Form>
  ) : (
    <LoaderWrapper>
      <Loader loading={true} size={30} />
    </LoaderWrapper>
  );
};
